<template>
    <div>
        <div>
            <panel variant="default">
                <template slot="header">
                    <h4 class="panel-title">
                        {{ $t('loanRepayment.repaymentHistory') }}
                    </h4>
                </template>
                <div style="min-height: 292px">
                    <div class="overflow-auto">
                        <div class="demo-spin-article">
                            <div
                                class="table-ui-detail"
                                style="max-height:26vh"
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="15%">
                                                {{
                                                    $t('loanRepayment.dueDate')
                                                }}
                                            </th>
                                            <th width="15%">
                                                {{
                                                    $t(
                                                        'loanRepayment.actualpaymentDate'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="10%"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.interestPaid'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="10%"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.interestDue'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                width="10%"
                                                class="tw-text-center"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.principalPaid'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                width="10%"
                                                class="tw-text-center"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.principalDue'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                width="10%"
                                                class="tw-text-center"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.monthlyFeePaid'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                width="10%"
                                                class="tw-text-center"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.monthlyFeeDue'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                width="10%"
                                                class="tw-text-center"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.rescueFeePaid'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                width="10%"
                                                class="tw-text-center"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.rescueFeeDue'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.amountRepay'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t('loanRepayment.balance')
                                                }}
                                            </th>
                                            <th width="15%" class="text-center">
                                                {{ $t('loanRepayment.isOwe') }}
                                            </th>
                                            <th class="text-center" width="10%">
                                                {{
                                                    $t(
                                                        'loanRepayment.isConfirm'
                                                    )
                                                }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="isRepaymentSchedules">
                                        <tr
                                            v-for="(row,
                                            index) in loan.loan_repayments"
                                            :key="index"
                                        >
                                            <td class="tw-whitespace-nowrap">
                                                {{ row.cycle_payment_date }}
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                {{ row.actual_payment_date }}
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.interest_paid,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.interest_due,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.principal_paid,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.principal_due,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.monthly_fee_paid,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.monthly_fee_due,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.rescue_fee_paid,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.rescue_fee_due,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.actual_payment_amount,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.outstanding_balance,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td
                                                class="tw-whitespace-nowrap tw-text-center"
                                            >
                                                <span
                                                    class="badge bg-warning"
                                                    v-if="row.is_owe_cycle_paid"
                                                    >{{ $t('yes') }}</span
                                                >
                                                <span
                                                    v-else
                                                    class="badge bg-secondary"
                                                    >{{ $t('no') }}</span
                                                >
                                            </td>
                                            <td
                                                class="text-center tw-whitespace-nowrap tw-justify-items-center tw-items-center"
                                            >
                                                <span
                                                    class="badge bg-success"
                                                    v-if="row.is_approved"
                                                    >{{ $t('yes') }}</span
                                                >
                                                <span
                                                    v-else
                                                    class="badge bg-secondary"
                                                    >{{ $t('no') }}</span
                                                >
                                            </td>
                                        </tr>
                                        <tr v-if="isRepaymentSchedules">
                                            <th
                                                width="13%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            ></th>
                                            <th
                                                width="14%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.totalAmount'
                                                    )
                                                }}
                                                :
                                            </th>
                                            <th
                                                width="11%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            >
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            totalInterestPaid,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </th>
                                            <th
                                                width="10%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            ></th>
                                            <th
                                                width="12%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            >
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            totalPrincipalPaid,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </th>
                                            <th
                                                width="11%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            ></th>
                                            <th
                                                width="12%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            >
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            totalMonthlyFeePaid,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </th>
                                            <th
                                                width="11%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            ></th>
                                            <th
                                                width="12%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            >
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            totalRescueFeePaid,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </th>
                                            <th
                                                width="11%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            ></th>
                                            <th
                                                width="10%"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            >
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            totalAmountRepay,
                                                            loan.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </th>
                                            <th
                                                width="25%"
                                                colspan="2"
                                                class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                            >
                                                {{
                                                    $t(
                                                        'loanRepayment.numberOFPayment'
                                                    )
                                                }}
                                                :
                                            </th>
                                            <th
                                                width="12%"
                                                class="tw-whitespace-nowrap tw-text-center tw-font-bold tw-uppercase text-blue"
                                            >
                                                {{ totalNumberOfPayment }}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Spin size="large" fix v-if="scheduleLoading">
                        </Spin>
                    </div>
                </div>
            </panel>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'repaymentHistory',
    props: ['value'],
    data () {
        return {
            model: {},
            scheduleLoading: false,
            pdf_view: false
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('creditOperation/loanRepayment', ['loanDisbursements']),
        ...mapGetters(['formatNumber', 'signBycurrencyId']),
        loan () {
            if (this.loanDisbursements.length > 0 && this.model.loan_id) {
                return this.loanDisbursements.find(
                    l => l.loan_id == this.model.loan_id
                )
            }
            return []
        },
        currency () {
            if (this.model.loan_id) {
                return this.loan.currency
            }
            return {
                currency_id: this.$store.state.authUser.sys_setting[0]
                    .default_currency_id,
                currency_code: this.$store.state.authUser.sys_setting[0]
                    .default_currency
            }
        },
        isRepaymentSchedules () {
            if (this.model.loan_id) {
                return true
            }
            return false
        },
        totalPrincipalPaid () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return parseFloat(
                        this.loan.loan_repayments
                            .reduce(
                                (n, { principal_paid }) => n + principal_paid,
                                0
                            )
                            .toFixed(4)
                    )
                }
                return 0
            }
            return 0
        },
        totalPrincipalDue () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return parseFloat(
                        this.loan.loan_repayments
                            .reduce(
                                (n, { principal_due }) => n + principal_due,
                                0
                            )
                            .toFixed(4)
                    )
                }
                return 0
            }
            return 0
        },
        totalMonthlyFeePaid () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return parseFloat(
                        this.loan.loan_repayments
                            .reduce(
                                (n, { monthly_fee_paid }) =>
                                    n + monthly_fee_paid,
                                0
                            )
                            .toFixed(4)
                    )
                }
                return 0
            }
            return 0
        },
        totalMonthlyFeeDue () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return parseFloat(
                        this.loan.loan_repayments
                            .reduce(
                                (n, { monthly_fee_due }) => n + monthly_fee_due,
                                0
                            )
                            .toFixed(4)
                    )
                }
                return 0
            }
            return 0
        },
        totalInterestPaid () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return parseFloat(
                        this.loan.loan_repayments
                            .reduce(
                                (n, { interest_paid }) => n + interest_paid,
                                0
                            )
                            .toFixed(4)
                    )
                }
                return 0
            }
            return 0
        },
        totalInterestDue () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return parseFloat(
                        this.loan.loan_repayments
                            .reduce(
                                (n, { interest_due }) => n + interest_due,
                                0
                            )
                            .toFixed(4)
                    )
                }
                return 0
            }
            return 0
        },
        totalRescueFeePaid () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return parseFloat(
                        this.loan.loan_repayments
                            .reduce(
                                (n, { rescue_fee_paid }) => n + rescue_fee_paid,
                                0
                            )
                            .toFixed(4)
                    )
                }
                return 0
            }
            return 0
        },
        totalAmountRepay () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return parseFloat(
                        this.loan.loan_repayments
                            .reduce(
                                (n, { actual_payment_amount }) =>
                                    n + actual_payment_amount,
                                0
                            )
                            .toFixed(4)
                    )
                }
                return 0
            }
            return 0
        },
        totalNumberOfPayment () {
            if (this.isRepaymentSchedules) {
                if (this.loan.length > 0) {
                    return this.loan.loan_repayments.length
                }
                return 0
            }
            return 0
        }
    }
}
</script>
<style scoped>
.table-ui-detail table td {
    border: 1px dashed #e9e9e9 !important;
    padding: 3px 9px;
}
</style>
