<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <fieldset class="mt-2">
                    <legend class="text-blue">
                        {{ $t('loanDisbursement.customerInfo') }}
                    </legend>
                    <div id="cover-image">
                        <img
                            :src="require('@/assets/cover.png')"
                            alt
                            class="cover"
                        />
                        <div id="img-frame" class="text-center mb-1">
                            <img
                                class="tw-rounded tw-h-8"
                                v-lazy="{
                                    src: photo(customer),
                                    error: onError(customer),
                                    loading: require('@/assets/Rolling-1s-200px.svg'),
                                    preLoad: 1.3,
                                    attempt: 1
                                }"
                            />
                        </div>
                    </div>
                    <div class="tw-text-sm tw-text-center tw-text-gray-400">
                        {{ customer ? customer.customer_code : '-' }}
                    </div>
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a href="#" class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanDisbursement.nameKh') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{ customer ? customer.customer_name_kh : '-' }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a href="#" class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanDisbursement.nameEn') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{ customer ? customer.customer_name_en : '-' }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a href="#" class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanDisbursement.contactNo') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{ customer ? customer.contact_no : '-' }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a href="#" class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanDisbursement.address') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 text-decoration-none"
                                style="font-size: 11px"
                            >
                                <small>
                                    {{
                                        customer
                                            ? customer.village
                                                ? customer.village.name_en +
                                                  ', '
                                                : ''
                                            : ''
                                    }}
                                    {{
                                        customer
                                            ? customer.commune
                                                ? customer.commune.name_en +
                                                  ', '
                                                : ''
                                            : ''
                                    }}
                                    {{
                                        customer
                                            ? customer.district
                                                ? customer.district.name_en +
                                                  ', '
                                                : ''
                                            : ''
                                    }}
                                    {{
                                        customer
                                            ? customer.province
                                                ? customer.province.name_en +
                                                  '.'
                                                : '-'
                                            : '-'
                                    }}
                                </small>
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                </fieldset>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'

export default {
    name: 'customerInfo',
    props: ['value'],
    data () {
        return {
            model: {},
            errors: new Errors()
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('creditOperation/loanRepayment', ['loanDisbursements']),
        customer () {
            if (this.loanDisbursements.length > 0 && this.model.loan_id) {
                return this.loanDisbursements.find(
                    l => l.loan_id == this.model.loan_id
                ).customer
            }
            return []
        }
    },
    methods: {
        photo (record) {
            if (record) {
                return record.photo
            }

            return require('@/assets/staff-default-man.png')
        },
        onError (record) {
            if (record) {
                return record.gender == 'Male'
                    ? require('@/assets/staff-default-man.png')
                    : require('@/assets/staff-default-women.png')
            }

            return require('@/assets/staff-default-man.png')
        }
    }
}
</script>
<style scoped>
.cover {
    opacity: 0.2;
}
#cover-image img {
    object-fit: contain;
    width: 100%;
}
#img-frame {
    display: block;
    position: relative;
    margin-top: -40%;
}
#img-frame img {
    object-fit: contain;
    width: 117.84px;
    height: 150.92px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 3px;
    margin: 0 auto;
}
.widget-list .widget-list-item .widget-list-media,
.widget-list .widget-list-item .widget-list-content,
.widget-list .widget-list-item .widget-list-action {
    padding: 8px 0;
}
</style>
